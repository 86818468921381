import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import axios from "axios";
import {
  MDBBtn,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBRow,
  MDBSpinner
} from "mdb-react-ui-kit";
import Alert from "react-bootstrap/Alert";

export default function AddCategory() {
  const [submit,setSubmit]=useState(false);
  const [formData, setFormData] = useState({
    name: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmit(true);
      // Send data to the backend using Axios
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/category`, formData);
      if (response.status == 200) {
        setSubmit(false);
        window.location.href = "/manage-categories";
      }
      else {
        console.log("error")
      }
      // Handle response from backend// Optionally, you can perform additional actions such as redirecting the user or showing a success message
    } catch (error) {
      console.error("Error:", error);
      // Optionally, you can show an error message to the user
    }
  };
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "15%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "85%" }}>
        <Header />
        <h3
          id="addpro"
          style={{
            fontWeight: "bold",
            fontFamily: "Bahnschrift",
            textAlign: "left",
            marginTop: "15px",
          }}
        >
          Add Category
        </h3>
        <center>
          <MDBCol md="7">
            <MDBCard className="my-5">
              <form onSubmit={handleSubmit}>
                <MDBCardBody className="p-5">
                  <MDBRow>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Category Name"
                        name="name"
                        id="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn
                    type="submit"
                    className="w-100 mb-4"
                    size="md"
                    style={{
                      backgroundColor: "#342a64",
                      color: "white",
                    }}
                  >
                    {submit ? <MDBSpinner></MDBSpinner> : <span>Add Category</span>}
                  </MDBBtn>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </center>
      </div>
    </div>
  );
}
