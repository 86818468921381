import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import axios from "axios";
import {
  MDBBtn,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBRow,
  MDBSpinner
} from "mdb-react-ui-kit";

export default function AddSubategory() {
  const [data, setData] = useState([]);
  const [submit,setSubmit]=useState(false);
  const [formData, setFormData] = useState({
    category: "",
    name: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`);
      if (response.status == 200) {
        setData(response.data)
      }
      else {
        console.log("error")
      }
    }
    getData()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmit(true);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/subcategory`, formData);
      if (response.status == 200) {
        setSubmit(false);
        window.location.href = "/manage-sub-categories";
      }
      else {
        console.log("error")
      }
    } catch (error) {
      console.error("Error:", error);

    }
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "15%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "85%" }}>
        <Header />
        <h3
          id="addpro"
          style={{
            fontWeight: "bold",
            fontFamily: "Bahnschrift",
            textAlign: "left",
            marginTop: "15px",
          }}
        >
          Add Sub-Category
        </h3>
        <center>
          <MDBCol md="7">
            <MDBCard className="my-5">
              <form onSubmit={handleSubmit}>
                <MDBCardBody className="p-5">
                  <MDBRow>
                    <MDBCol md={12}>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Choose Category</option>
                        {data.map((category) => (
                          <option key={category._id} value={category._id}>{category.name}</option>
                        ))}
                      </select>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginTop:"20px"}}>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Sub-Category Name"
                        name="name"
                        id="name"
                        type="text"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBBtn
                    type="submit"
                    className="w-100 mb-4"
                    size="md"
                    style={{
                      backgroundColor: "#342a64",
                      color: "white",
                    }}
                  >
                    {submit?<MDBSpinner></MDBSpinner>:<span>Add Sub-Category</span>}
                  </MDBBtn>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </center>
      </div>
    </div>
  );
}
