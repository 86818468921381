import './App.css';
import {Switch,Route} from 'react-router-dom';
import Home from './Components/home';
import Login from './Components/login';
import ManageProducts from './Components/mangaProducts';
import Addproducts from './Components/addProducts';
import AddCategory from './Components/addCategory';
import ManageCategories from './Components/manageCategories';
import ChangePassword from './Components/changepassword';
import SubCategories from './Components/subcategories';
import AddSubategory from './Components/addsubcategory';
import Permission from './Components/permission';
import UpdateProducts from './Components/updateProduct';
import UpdateCatgory from './Components/updateCategory';
import UpdateSubategory from './Components/updateSubCategory';
import PrivateRoute from './Components/private';
import Orders from './Components/orders';

function App() {
  return (
    <div className="app">
      <Switch>
        <Route exact path="/" component={Login}/>
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/manage-products" component={ManageProducts}/>
        <PrivateRoute path="/add-subcategory" component={AddSubategory}/>
        <PrivateRoute path="/add-product" component={Addproducts}/>
        <PrivateRoute path="/add-category" component={AddCategory}/>
        <PrivateRoute path="/manage-categories" component={ManageCategories}/>
        <PrivateRoute path="/manage-sub-categories" component={SubCategories}/>
        <PrivateRoute path="/change-credentials" component={ChangePassword}/>
        <PrivateRoute path="/update-product/:id" component={UpdateProducts}/>
        <PrivateRoute path="/update-category/:id" component={UpdateCatgory}/>
        <PrivateRoute path="/update-subcategory/:id" component={UpdateSubategory}/>
        <PrivateRoute path="/orders" component={Orders}/>
        <PrivateRoute path="/not-permission" component={Permission}/>
      </Switch>
    </div>
  );
}

export default App;
