import React, { useState } from "react";
import Sidebar from "./sidebar";
import axios from 'axios';
import Header from "./navbar";
import Cookies from 'js-cookie';
import {
  MDBBtn,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ChangePassword() {
  const [submit,setSubmit]=useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const newPassword = formData.get('newpass');
    const confirmPassword = formData.get('confirm');
    const data = {
      ppass: formData.get('current'),
      password: formData.get('newpass')
    }

    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match.');
      return;
    }
    const userData = Cookies.get('user');
    const user = userData ? JSON.parse(userData) : null;
    try {
      setSubmit(true);
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/${user._id}`, data);
      if (response.status === 200) {
        setSubmit(false);
        notify();
      } else {
        console.log('Password change failed');
      }
    } catch (error) {
      setSubmit(false);
      Notifyerror();
    }
  };

  const notify = () => {
    toast.success('Password Changed');
  };

  const Notifyerror = () => {
    toast.error('Current password is incorrect');
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "17%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "83%" }}>
        <ToastContainer/>
        <Header />
        <h3
          id="addpro"
          style={{
            fontWeight: "bold",
            fontFamily: "Bahnschrift",
            textAlign: "left",
            marginTop: "15px",
          }}
        >
          Change Credentials
        </h3>
        <center>
          <MDBCol md="7">
            <MDBCard className="my-5">
              <form onSubmit={handleSubmit}>
                <MDBCardBody className="p-5">
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Current Password"
                    name="current"
                    id="current"
                    type="password"
                    required
                  />
                  <MDBInput
                    wrapperClass="mb-4"
                    label="New Password"
                    name="newpass"
                    id="newpass"
                    type="password"
                    required
                  />
                  <MDBInput
                    wrapperClass="mb-4"
                    label="Confirm Password"
                    name="confirm"
                    id="confirm"
                    type="password"
                    required
                  />
                  <span id="pass-error"></span>
                  <br />
                  <MDBBtn
                    type="submit"
                    className="w-100 mb-4"
                    size="md"
                    style={{
                      backgroundColor: "#342a64",
                      color: "white",
                    }}
                  >
                    {submit? <MDBSpinner></MDBSpinner> : <span>Change</span>}
                  </MDBBtn>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </center>
      </div>
    </div>
  );
}