import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  MDBBtn,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBRow,
  MDBSpinner
} from "mdb-react-ui-kit";

export default function UpdateCatgory() {
  const { id } = useParams();
  const [submit,setSubmit]=useState(false);
  const [data, setData] = useState({
    name: ""
  });
  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category/${id}`);
      if (response.status == 200) {
        setData(response.data)
      }
      else {
        console.log("error")
      }
    }
    getData()
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmit(true);
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/category/${id}`, data);
      if(response.status == 200){
        setSubmit(false);
        window.location.href = '/manage-categories'
      }
      else{
        console.log("error")
      }
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "15%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "85%" }}>
        <Header />
        <h3
          id="addpro"
          style={{
            fontWeight: "bold",
            fontFamily: "Bahnschrift",
            textAlign: "left",
            marginTop: "15px",
          }}
        >
          Update Category
        </h3>
        <center>
          <MDBCol md="7">
            <MDBCard className="my-5">
              <form
                onSubmit={handleSubmit}
                id="addform"
              >
                <MDBCardBody className="p-5">
                  <MDBRow>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Category Name"
                        name="name"
                        id="name"
                        type="text"
                        required
                        value={data.name}
                        onChange={handleChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn
                    type="submit"
                    className="w-100 mb-4"
                    size="md"
                    style={{
                      backgroundColor: "#342a64",
                      color: "white",
                    }}
                  >
                    {submit?<MDBSpinner></MDBSpinner>:<span>Update Category</span>}
                  </MDBBtn>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </center>
      </div>
    </div>
  );
}
