import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import axios from 'axios';
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBTextArea,
  MDBSpinner,
} from "mdb-react-ui-kit";

export default function Addproducts() {
  const [file, setFile] = useState("");
  const [data, setData] = useState([]);
  const [subcategories,setSubCategories]=useState([]);
  const [selectedSub,setSelectedSub]=useState([]);
  const [submit,setSubmit]=useState(false);
  const [formData, setFormData] = useState({
    title: '',
    category: '',
    subcategory:'',
    detail: '',
    quantity: '',
    price: ''
  });

  useEffect(() => {
    getData();
    getSubCategories();
  }, []);

  const getData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`);
    if (response.status == 200) {
      setData(response.data);
    }
    else {
      console.log("error")
    }
  }

  const getSubCategories= async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/subcategory`);
    if (response.status == 200) {
      setSubCategories(response.data);
    }
    else {
      console.log("error")
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setSelectedSub(subcategories.filter((item)=>item.category._id==e.target.value));
  };

  const handleSubChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmit(true);
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "qyipkvm1");
      data.append("cloud_name", "dvojlf2hl");
      const uploadRes = await axios.post(
        "https://api.cloudinary.com/v1_1/dvojlf2hl/image/upload",
        data
      );

      const { url } = uploadRes.data;
      console.log(formData);
      const data1 = {
        ...formData,
        img: url
      }
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product`, data1);
      if (response.status === 200) {
        window.location.href = '/manage-products'
        // Reset the form after successful submission
        setFormData({
          title: '',
          image: '',
          category: '',
          detail: '',
          quantity: '',
          price: ''
        });
      }
      else {
        setSubmit(false);
        console.log('Product not added');
      }
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "15%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "85%" }}>
        <Header />
        <h3
          id="addpro"
          style={{
            fontWeight: "bold",
            fontFamily: "Bahnschrift",
            textAlign: "left",
            marginTop: "15px",
          }}
        >
          Add Product
        </h3>
        <center>
          <MDBCol md="7">
            <MDBCard className="my-5">
              <form id="addform" onSubmit={handleSubmit}>
                <MDBCardBody className="p-5">
                  <MDBRow>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Product Title"
                        name="title"
                        id="title"
                        type="text"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={6}>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ marginTop: "-1px" }}
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled selected>Choose Category</option>
                        {data.map((category) => (
                          <option key={category._id} value={category._id}>{category.name}</option>
                        ))}
                      </select>
                    </MDBCol>
                    <MDBCol md={6}>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ marginTop: "-1px" }}
                        name="subcategory"
                        value={formData.subcategory}
                        onChange={handleSubChange}
                      >
                        <option value="" disabled selected>Choose Sub-Category</option>
                        {selectedSub.map((subcategory) => (
                          <option key={subcategory._id} value={subcategory.name}>{subcategory.name}</option>
                        ))}
                      </select>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow style={{marginTop:"20px"}}>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        name="image"
                        id="image"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md={12}>
                      <MDBTextArea
                        wrapperClass="mb-4"
                        name="detail"
                        id="detail"
                        label="Product Details"
                        rows={6}
                        type="text"
                        value={formData.detail}
                        onChange={handleChange}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md={6}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Product Quantity"
                        type="number"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        required
                      />
                    </MDBCol>
                    <MDBCol md={6}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Product Price"
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn
                    type="submit"
                    className="w-100 mb-4"
                    size="md"
                    style={{
                      backgroundColor: "#342a64",
                      color: "white",
                      marginTop: "10px"
                    }}
                  >
                    {submit?<MDBSpinner></MDBSpinner>:<span>Add Product</span>}
                  </MDBBtn>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </center>
      </div>
    </div>
  );
}
