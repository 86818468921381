import React from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import { MDBTable, MDBTableHead, MDBTableBody, MDBRow } from 'mdb-react-ui-kit';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';

export default function Orders() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData()
  }, []);

  const getData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/getOrders`);
    if (response.status == 200) {
      setData(response.data)
    }
    else {
      console.log("error")
    }
  }

  const handleDelete = async (id) => {
    if(window.confirm('Are you sure you want to delete')){
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/category/${id}`);
        if (response.status == 200) {
          console.log('Product deleted successfully');
          getData();
        }
        else {
          console.log("error")
        }
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  }

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "17%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "83%" }}>
        <Header />
        <div
          className="head"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3
            id="addpro"
            style={{
              fontWeight: "bold",
              fontFamily: "Bahnschrift",
              textAlign: "left",
              marginTop: "15px",
            }}
          >
            Orders
          </h3>
        </div>
        <MDBRow id="tables">
          <MDBTable striped>
            <MDBTableHead>
              <tr>
                <th scope='col'>Sr#</th>
                <th scope='col'>User Name</th>
                <th scope='col'>Product Name</th>
                <th scope='col'>Quantity</th>
                <th scope='col'>Unit Price</th>
                <th scope='col'>Total Price</th>
                <th scope='col'>Dated</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data && data.map((item, index) => (
                <tr key={index}>
                  <th scope='row'>{++index}</th>
                  <th scope='row'>{item.userId.name}</th>
                  <th scope='row'>{item.productId.title}</th>
                  <th scope='row'>{item.quantity}</th>
                  <th scope='row'>{item.price}</th>
                  <th scope='row'>{item.price*item.quantity}</th>
                  <th scope='row'>{new Date(item.createdAt).toLocaleString()}</th>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </MDBRow>
      </div>
    </div>
  );
}
