import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon } from "mdbreact";
import Sidebar from "./sidebar";
import Header from "./navbar";
import axios from 'axios'
export default function Home() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [cate, setCate] = useState([]);
  useEffect(() => {
    setShow(true);
  }, []);
  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product`);
      if (response.status == 200) {
        console.log(response.data)
        setData(response.data)
      }
      else {
        console.log("error")
      }
    }
    const getCate = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`);
      if (response.status == 200) {
        setCate(response.data);
      }
      else {
        console.log("error");
      }
    }
    getCate()
    getData()
  }, [])
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "15%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "85%" }}>
        <Header />
        <div className={`welcome-animation ${show ? "show" : ""}`}>
          <center>
            <h1 style={{ marginTop: "25px" }}>Welcome to Admin Dashboard!</h1>
          </center>
          <MDBRow id="homedashboard">
            <MDBCol md="4">
              <MDBCard style={{ marginTop: "5px" }} className={`allcards`}>
                <MDBCardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <MDBIcon
                        icon="box"
                        className="mr-2"
                        style={{ marginRight: "10px" }}
                      />
                      Products
                    </div>
                    <h2>{data && data.length}</h2>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol md="4">
              <MDBCard style={{ marginTop: "5px" }}>
                <MDBCardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <MDBIcon
                        icon="box"
                        className="mr-2"
                        style={{ marginRight: "10px" }}
                      />
                      Categories
                    </div>
                    <h2>{cate && cate.length}</h2>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    </div>
  );
}
