import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from "js-cookie";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Alert from "react-bootstrap/Alert";

function Login() {
  const [submit,setSubmit]=useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmit(true);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setSubmit(false);
        Cookies.set('user', JSON.stringify(response.data.user), { expires: 2 });
        Cookies.set('token', response.data.token, { expires: 2 });
        window.location.href = '/home';
      } else {
        setSubmit(false);
        notify();
        console.error('Login failed');
      }
    } catch (error) {
      setSubmit(false);
      notify();
      console.error('Error:', error);
    }
  };

  const notify = () => {
    toast.error('Invalid Login Details');
  };

  return (
    <MDBContainer fluid className="vh-100" style={{ backgroundImage: 'url("../Assets/backimage.png")', backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <ToastContainer/>
      <MDBCol md="5">
        <MDBCard className="my-5">
          <MDBCardBody className="p-5">
            <form
              onSubmit={handleSubmit}
            >
              <center><h4 style={{ marginBottom: "7%", fontWeight: "bolder" }}>Admin Login</h4></center>

              <MDBInput
                wrapperClass="mb-4"
                label="Email"
                name="email"
                id="form1"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <MDBInput
                wrapperClass="mb-4"
                label="Password"
                id="form1"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <MDBBtn
                className="w-100 mb-4"
                size="md"
                style={{
                  backgroundColor: "#342a64",
                  color: "white",
                }}
              >
                {
                  submit? <MDBSpinner></MDBSpinner> : <span>Sign In</span>
                }
              </MDBBtn>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBContainer>
  );
}

export default Login;
