import React from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import { MDBTable, MDBTableHead, MDBTableBody, MDBRow } from 'mdb-react-ui-kit';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios'
import { useState, useEffect } from 'react'

export default function SubCategories() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/subcategory`);
    if (response.status == 200) {
      setData(response.data)
    }
    else {
      console.log("error")
    }
  }

  const handleDelete = async (id) => {
    if(window.confirm('Are you sure you want to delete?')){
      try {
        const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/subcategory/${id}`);
        if (response.status == 200) {
          getData();
        }
        else {
          console.log("error")
        }
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  }

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "17%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "83%" }}>
        <Header />
        <div
          className="head"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3
            id="addpro"
            style={{
              fontWeight: "bold",
              fontFamily: "Bahnschrift",
              textAlign: "left",
              marginTop: "15px",
            }}
          >
            Manage Sub-Categories
          </h3>
          <a href="/add-subcategory">
            <Button
              style={{
                backgroundColor: "#342a64",
                border: "none",
                color: "white",
                fontWeight: "bold",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              Add Sub-Category
            </Button>
          </a>
        </div>
        <MDBRow id="tables">
          <MDBTable striped>
            <MDBTableHead>
              <tr>
                <th scope='col'>Sr#</th>
                <th scope='col'>Category Name</th>
                <th scope='col'>Sub-Category</th>
                <th scope='col'>Added At</th>
                <th scope='col'>Updated At</th>
                <th scope='col'>Update</th>
                <th scope='col'>Delete</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data && data?.map((cat, index) => (
                <tr key={index}>
                  <th scope='row'>{++index}</th>
                  <th scope='row'>{cat?.category?.name}</th>
                  <th scope='row'>{cat.name}</th>
                  <th scope='row'>{new Date(cat.createdAt).toLocaleString()}</th>
                  <th scope='row'>{new Date(cat.updatedAt).toLocaleString()}</th>
                  <th scope='row'><Link to={`/update-subcategory/${cat._id}`}>Update</Link></th>
                  <th scope='row'><Link onClick={() => handleDelete(cat._id)}>Delete</Link></th>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </MDBRow>
      </div>
    </div>
  );
}
