import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import axios from 'axios'
import { useParams } from 'react-router-dom';
import {
  MDBBtn,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBRow
} from "mdb-react-ui-kit";
import Alert from "react-bootstrap/Alert";

export default function UpdateSubcategory() {
  const { id } = useParams();
  const [cate, setCate] = useState([]);
  const [data, setData] = useState({
    category: "",
    name: ""
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/subcategory/${id}`);
        if (response.status === 200) {
          console.log(response.data)
          setData(response.data);
        } else {
          console.log("Error fetching subcategory data");
        }
      } catch (error) {
        console.error('Error fetching subcategory data:', error);
      }
    };

    const getCat = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`);
        if (response.status === 200) {
          setCate(response.data);
        } else {
          console.log("Error fetching category data");
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    getCat();
    getData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/subcategory/${id}`, data);
      if (response.status === 200) {
        window.location.href = '/manage-sub-categories';
      } else {
        console.log("Error updating subcategory");
      }
    } catch (error) {
      console.error('Error updating subcategory:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "15%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "85%" }}>
        <Header />
        <h3
          style={{
            fontWeight: "bold",
            fontFamily: "Bahnschrift",
            textAlign: "left",
            marginTop: "15px",
          }}
          id="addpro"
        >
          Update Sub-Category
        </h3>
        <center>
          <MDBCol md="7">
            <MDBCard className="my-5">
              <form onSubmit={handleSubmit}>
                <MDBCardBody className="p-5">
                  <MDBRow>
                    <MDBCol md={12}>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="category"
                        value={data.category}
                        onChange={handleChange}
                      >
                        <option value="" disabled>Select Category</option>
                        {cate.map((category) => (
                          <option key={category._id} value={category._id}>{category.name}</option>
                        ))}
                      </select>
                    </MDBCol>
                    <MDBCol md={12} style={{ marginTop: "15px" }}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Sub-Category Name"
                        name="name"
                        id="name"
                        type="text"
                        required
                        value={data.name}
                        onChange={handleChange}
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBBtn
                    type="submit"
                    className="w-100 mb-4"
                    size="md"
                    style={{
                      backgroundColor: "#342a64",
                      color: "white",
                    }}
                  >
                    Update Sub-Category
                  </MDBBtn>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </center>
      </div>
    </div>
  );
}