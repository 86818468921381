import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./navbar";
import { Button } from "react-bootstrap";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdb-react-ui-kit';
import { Nav } from "react-bootstrap";
import { Link } from 'react-router-dom';
import axios from "axios";

export default function ManageProducts() {
  const [activeCategory, setActiveCategory] = useState("all");
  const [data, setData] = useState([]);
  const [cate, setCate] = useState([]);


  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product`);
      if (response.status == 200) {
        console.log(response.data)
        setData(response.data)
      }
      else {
        console.log("error")
      }
    }
    const getCate = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`);
      if (response.status == 200) {
        console.log(response.data)
        setCate(response.data)
      }
      else {
        console.log("error")
      }
    }
    getCate()
    getData()
  }, [])

  const handleDelete = async (id) => {
    try {
      // Send a DELETE request to delete the product
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/${id}`);
      if (response.status == 200) {
        console.log('Product deleted successfully');
        window.location.reload();
      }
      else {
        console.log("error")
      }

      // Optionally, you can redirect the user or show a success message
    } catch (error) {
      console.error('Error deleting product:', error);
      // Optionally, you can show an error message to the user
    }
  }

  const handleClick = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/category/${id}`);
    if (response.status == 200) {
      console.log(response.data)
      setData(response.data)
    }
    else {
      console.log("error")
    }
  }

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "17%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "83%"}}>
        <Header />
        <div
          className="head"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3
            id="addpro"
            style={{
              fontWeight: "bold",
              fontFamily: "Bahnschrift",
              textAlign: "left",
              marginTop: "30px",
            }}
          >
            Manage Products
          </h3>
          <a href="/add-product">
            <Button
              style={{
                backgroundColor: "#342a64",
                border: "none",
                color: "white",
                fontWeight: "bold",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              Add Product
            </Button>
          </a>
        </div>
        <Nav
          id="addpro"
          variant="tabs"
          className="underline-nav"
          style={{ marginTop: "10px" }}
        >
          {cate && cate.map((cat, index) => (
            <Nav.Item key={index}>
              <Nav.Link
                eventKey={cat._id}
                active={activeCategory === cat._id}
                onClick={() => handleClick(cat._id)}
              >
                {cat.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{ margin: "30px",marginLeft:"10%" }}>
          {data && data.map((pro, index) => (
            <MDBCol key={index} lg={4} md={6}>
              <MDBCard className='h-100'>
                <MDBCardImage
                  src={pro.img}
                  alt='...'
                  position='top'
                  style={{ width: "100%", height: "200px" }}
                />
                <MDBCardBody>
                  <MDBCardTitle>{pro.title}</MDBCardTitle>
                  <MDBCardText>
                    {pro.detail} <br />
                    <p><b>Price: </b>{pro.price}</p>
                    <p style={{ marginTop: "-15px" }}><b>Quantity: </b>{pro.quantity>3?"High":pro.quantity==2?"Medium":pro.quantity==1?"Low":""}</p>
                    <p style={{ marginTop: "-15px" }}><b>Sub Category: </b>{pro.subcategory}</p>
                    <center>
                      <div style={{ marginTop: "10px" }}>
                        <Link to={`/update-product/${pro._id}`}>
                          <MDBBtn style={{ backgroundColor: "green", marginLeft: "10px",marginTop:"8px" }}>Update</MDBBtn>
                        </Link>
                        <MDBBtn style={{ backgroundColor: "red",marginLeft:"10px",marginTop:"8px" }} onClick={() => handleDelete(pro._id)}>Delete</MDBBtn>
                      </div>
                    </center>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))}
        </MDBRow>

      </div>
    </div>
  );
}
