import React, { useEffect, useState } from "react";
import Sidebar from "./sidebar";
import axios from 'axios'
import Header from "./navbar";
import { useParams } from 'react-router-dom';
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBTextArea,
} from "mdb-react-ui-kit";
import 'react-quill/dist/quill.snow.css';

export default function UpdateProducts() {
  const { id } = useParams();
  const [file, setFile] = useState("");
  const [data2, setData2] = useState([]);
  const [selectedSubCategory,setSelectedSubCategory]=useState([]);
  const [subcategories,setSubCategories]=useState([]);
  const [data, setData] = useState({
    title: "",
    category: "",
    detail: "",
    quantity: 0,
    price: 0
  });

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}`);
      if (response.status == 200) {
        console.log("sub",subcategories);
        console.log();
        getSubCategories(response.data);
        setData(response.data)
      }
      else {
        console.log("error")
      }
    }
    const getData2 = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/category`);
      if (response.status == 200) {
        setData2(response.data)
      }
      else {
        console.log("error")
      }
    }
    getData2()
    getData()
  }, [id]);

  const getSubCategories= async (data) => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/subcategory`);
    if (response.status == 200) {
      setSelectedSubCategory(response.data.filter((item)=>item.category._id==data.category));
    }
    else {
      console.log("error")
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [name]: value
    }));
    setSelectedSubCategory(subcategories.filter((item)=>item.category._id==e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (file != "") {
        const pdata = new FormData();
        pdata.append("file", file);
        pdata.append("upload_preset", "qyipkvm1");
        pdata.append("cloud_name", "dvojlf2hl");
        const uploadRes = await axios.post(
          "https://api.cloudinary.com/v1_1/dvojlf2hl/image/upload",
          pdata
        );

        const { url } = uploadRes.data;
        const data1 = {
          ...data,
          img: url
        }
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/product/${id}`, data1);
        if (response.status == 200) {
          window.location.href = '/manage-products'
        }
        else {
          console.log("error")
        }
        console.log('Product updated successfully');
      }
      else {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/product/${id}`, data);
        if (response.status == 200) {
          window.location.href = '/manage-products'
        }
        else {
          console.log("error")
        }
        console.log('Product updated successfully');
      }
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "15%" }}>
        <Sidebar />
      </div>
      <div style={{ width: "85%" }}>
        <Header />
        <h3 id="addpro" style={{ fontWeight: "bold", fontFamily: "Bahnschrift", textAlign: "left", marginTop: "15px" }}>
          Update Product
        </h3>
        <center>
          <MDBCol md="7">
            <MDBCard className="my-5">
              <form id="addform" onSubmit={handleSubmit}>
                <MDBCardBody className="p-5">
                  <MDBRow>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Product Title"
                        name="title"
                        id="Title"
                        type="text"
                        value={data.title}
                        onChange={handleChange}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        name="image"
                        id="image"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={6}>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ marginTop: "4px" }}
                        name="category"
                        value={data.category}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Choose Category</option>
                        {data2.map((category) => (
                          <option key={category._id} value={category._id}>{category.name}</option>
                        ))}
                      </select>
                    </MDBCol>
                    <MDBCol md={6}>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ marginTop: "4px" }}
                        name="category"
                        value={data.category}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Choose Subcategory</option>
                        {selectedSubCategory.map((category) => (
                          <option key={category._id} value={category._id}>{category.name}</option>
                        ))}
                      </select>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginTop:"20px"}}>
                    <MDBCol md={12}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Product Details"
                        name="detail"
                        id="detail"
                        type="textarea"
                        value={data.detail}
                        onChange={handleChange}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md={6}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Product Quantity"
                        name="quantity"
                        type="number"
                        value={data.quantity}
                        onChange={handleChange}
                      />
                    </MDBCol>
                    <MDBCol md={6}>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Product Price"
                        name="price"
                        type="number"
                        value={data.price}
                        onChange={handleChange}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBBtn type="submit" className="w-100 mb-4" size="md" style={{ backgroundColor: "#342a64", color: "white", marginTop: "10px" }}>
                    Update Products
                  </MDBBtn>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </center>
      </div>
    </div>
  );
}
